<template>
  <div
    v-if="!animationName"
    class="grid-wrapper"
    :style="style"
  >
    <slot />
  </div>
  <transition-group
    v-else
    class="grid-wrapper"
    :name="animationName"
    :style="style"
    tag="div"
  >
    <slot />
  </transition-group>
</template>

<script>
export default {
  computed: {
    style () {
      return {
        'grid-template-columns': `repeat(${this.repeat}, minmax(${this.itemMinWidth}, ${this.itemMaxWidth}))`,
        'grid-gap': this.gridGap
      }
    }
  },
  props: {
    animationName: {
      default: '',
      type: String
    },
    gridGap: {
      default: '16px',
      type: String
    },
    itemMinWidth: {
      default: 'max-content',
      type: String
    },
    itemMaxWidth: {
      default: '1fr',
      type: String
    },
    repeat: {
      default: 'auto-fit',
      type: [Number, String]
    }
  }
}
</script>

<style lang="stylus" scoped>
.grid-wrapper
  display grid
  grid-auto-rows 1fr
</style>
